<template>
  <b-row>
    <b-col sm="12" md="8">
      <my-card :isloading="cardloading">
        <template slot="body">
          <form-validation @submit="submitForm">
            <b-row>
              <b-col sm="12" md="6">
                <form-select placeholder="Pilih Kategori / Kosongkan untuk Parent" ref="parentSelect" @value-changed="parentChange" url="v1/expense_header_select2" v-model="id_parent" label="Kategori"></form-select>
              </b-col>
              <b-col sm="12" md="8">
                <form-input :rules="{required: true}" v-model="code" label="Kode"></form-input>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: true}" v-model="name" label="Nama Biaya"></form-input>
              </b-col>
              <b-col sm="12" v-show="id_parent">
                <form-select ref="supplierSelect" @value-changed="supplierChange" :rules="{required: false}" url="v1/supplier_select2" v-model="id_supplier" label="Pemasok Default"></form-select>
              </b-col>
              <b-col sm="12" v-show="id_parent">
                <form-input-number :rules="{required: id_parent>0}" v-model="standart_price" label="Standart Biaya"></form-input-number>
              </b-col>
              <b-col sm="12">
                <form-input :rules="{required: false}" v-model="note" label="Keterangan"></form-input>
              </b-col>
              <b-col>
                <form-checkbox v-model="is_active" label="Aktif"></form-checkbox>
              </b-col>
              <b-col sm="12" class="mt-3">
                <button-save :isloading="isloading"></button-save>
                <button-back class="ml-1" :to="{name:'expenses'}"></button-back>
              </b-col>
            </b-row>
          </form-validation>
        </template>
      </my-card>
    </b-col>
  </b-row>
</template>

<script>
import FormInput from '@/my-components/FormInput.vue'
import FormInputNumber from '@/my-components/FormInputNumber.vue'
import FormCheckbox from '@/my-components/FormCheckbox.vue'
import ButtonSave from '@/my-components/ButtonSave.vue'
import ButtonBack from '@/my-components/ButtonBack.vue'
import { mapActions } from 'vuex'
import FormValidation from '@/my-components/FormValidation.vue'
import FormSelect from '@/my-components/FormSelect.vue'

export default {
  components:{
    FormInput,
    ButtonSave,
    ButtonBack,
    FormValidation,
    FormSelect,
    FormCheckbox,
    FormInputNumber
  },
  data(){
    return {
      id: this.$route.params.id||null,
      code:'',
      name:'',
      id_parent: null,
      parent_name:'',
      id_supplier:null,
      supplier_name:'',
      note:'',
      standart_price:0,
      is_active: true,
      isloading: false,
      cardloading: false
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'expenses/store',
      dispatchShow: 'expenses/show',
      dispatchUpdate: 'expenses/update',
    }),
    parentChange(json){
      if(json.label) this.parent_name = json.label;
      else this.parent_name = '';
    },
    supplierChange(json){
      if(json.label) this.supplier_name = json.label;
      else this.supplier_name = '';
    },
    async getData(){
      this.cardloading = true
      const data = await this.dispatchShow(this.id)
      this.name = data.name
      this.code = data.code
      this.id_parent = data.id_parent
      this.id_supplier = data.id_supplier
      this.standart_price = data.standart_price
      this.parent_name = data.parent_name
      this.supplier_name = data.supplier_name
      this.note = data.note
      this.is_active = data.is_active ? true : false
      if(data.id_parent){
        this.$refs.parentSelect.initOption([{"value":data.id_parent,"label":data.parent_name}])
      }
      if (data.id_supplier) {
        this.$refs.supplierSelect.initOption([{"value":data.id_supplier,"label":data.supplier_name}])        
      }
      this.cardloading = false
    },
    async submitForm(){
      this.isloading = true
      const {id,code,name,id_parent,parent_name,note,supplier_name,standart_price,id_supplier} = this
      let params = {id,code,name,id_parent,parent_name,note,supplier_name,standart_price,id_supplier}
      if(!this.id_parent) {
        params = {
          id,
          code,
          name,
          note
        }
      }
      params.is_active = this.is_active ? 1 : 0
      try {
        if (this.id) await this.dispatchUpdate(params);
        else await this.dispatchStore(params);
        this.toastSuccess("OK","Data berhasil disimpan!")
        this.$router.push({name:'expenses'})
      } catch (error) {
        this.handleError(error)
      }
      this.isloading = false
    },
  },
  mounted(){
    if(this.id) return this.getData();
  }
}
</script>

<style>

</style>